import React from "react";

const Navbar = () => {
  return (
    <div>
      <nav className="navbar fixed-top shadow-sm navbar-expand-lg navbar-dark py-3 py-lg-0 px-lg-5 custom-navbar">
        <a href="index.html" className="navbar-brand d-block d-lg-none">
          <h1 className="font-secondary text-white mb-n2">
            Miranda <span>y</span> Gastón
          </h1>
        </a>
        <button
          type="button"
          className="navbar-toggler"
          data-toggle="collapse"
          data-target="#navbarCollapse"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="collapse navbar-collapse justify-content-between"
          id="navbarCollapse"
        >
          <div className="navbar-nav ml-auto py-0">
            <a href="/" className="nav-item nav-link active">
              Inicio
            </a>
            <a href="#invitation" className="nav-item nav-link">
              Invitacion
            </a>
            <a href="#story" className="nav-item nav-link">
              Historia
            </a>
            <a href="#events" className="nav-item nav-link">
              Eventos
            </a>
          </div>
          <a href="index.html" className="navbar-brand mx-5 d-none d-lg-block">
            <h1 className="font-secondary text-white mb-n2">
            Miranda <span> & </span> Gastón
            </h1>
          </a>
          <div className="navbar-nav mr-auto py-0">
            <a href="#present" className="nav-item nav-link">
              Regalo
            </a>
            <a href="#thanks" className="nav-item nav-link">
              Agradecimiento
            </a>
            <a href="#confirm" className="nav-item nav-link">
              Confirmar
            </a>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
