import React from "react";
import Travel from "../img/Present.avif";
import AnimatedDiv from "./AnimatedDiv";

const Present = () => {
  return (
    <div className="container-fluid py-5" id="present">
      <div className="container py-5">
        <AnimatedDiv>
          <div className="section-title position-relative text-center">
            <h6
              className="text-uppercase text-primary mb-3 mt-3"
              style={{ letterSpacing: "3px" }}
            >
              <i className="fas fa-gift" style={{ fontSize: "30px" }}></i>
            </h6>
            <h1 className="font-secondary display-4 mb-3">Nuestro Regalo</h1>
            <h6
              style={{
                letterSpacing: "2px",
                color: "#474747",
                marginBottom: "20px",
              }}
            >
              es que nos acompañes en este día tan especial. Pero si quieres
              tener un detalle con nosotros podes colaborar con nuestra luna de
              miel
            </h6>
            <i className="far fa-heart text-dark"></i>
          </div>
        </AnimatedDiv>
        <div className="row m-0">
          <div className="col-md-6 p-0" style={{ minHeight: "400px" }}>
            <img
              alt="present"
              className="w-100 h-100"
              src={Travel}
              style={{ objectFit: "cover" }}
            />
          </div>
          <div className="col-md-6 p-0  text-md-left">
            <div className="h-100 d-flex flex-column bg-secondary  p-4">
              <div className="feature-left">
                <span className="icon">
                  <i className="fas fa-money-check text-primary-two present-icons"></i>
                </span>
                <div className="feature-copy">
                  <h5>CBU</h5>
                  <p>0070397830004015194746</p>
                </div>
              </div>
              <div className="feature-left">
                <span className="icon">
                  <i className="fas fa-comment text-primary-two present-icons"></i>
                </span>
                <div className="feature-copy">
                  <h5>ALIAS</h5>
                  <p>BARCA.GOMA.CAJA</p>
                </div>
              </div>
              <div className="feature-left">
                <span className="icon">
                  <i className="fas fa-user text-primary-two present-icons"></i>
                </span>
                <div className="feature-copy">
                  <h5>TITULAR</h5>
                  <p>Gastón Garat Herrera</p>
                </div>
              </div>
              <div className="feature-left">
                <span className="icon">
                  <i className="fas fa-box text-primary-two present-icons"></i>
                </span>
                <div className="feature-copy">
                  <h5>BUZÓN</h5>
                  <p>disponible en el salón</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Present;
