import React, { useState } from 'react';
import './App.css';
import Carousel from './components/Carousel';
import Navbar from './components/Navbar';
import Invitation from './components/Invitation';
import Story from './components/Story';
import Events from './components/Events';
import Dresscode from './components/Dresscode';
import Present from './components/Present';
import Assistance from './components/Assistance';
import Gallery from './components/Gallery';
import Thanks from './components/Thanks';
import Playlist from './components/Playlist';
import Footer from './components/Footer';
import GoToTopButton from './components/GoToTopButton';
import Testimonials from './components/Testimonials';

function App() {
  const [play, setPlay] = useState(false);
  return (
    <div className="App">
      <Carousel play={play} setPlay={setPlay}/>
      <Navbar />
      <Invitation />
      <Story />
      <Events />
      <Dresscode />
      <Present />
      <Assistance />
      <Testimonials />
      <Gallery />
      <Thanks play={play} setPlay={setPlay}/>
      <Playlist />
      <Footer />
      <GoToTopButton />
    </div>
  );
}

export default App;
