import React from "react";
import AnimatedDiv from "./AnimatedDiv";
import Story1 from "../img/Story1.avif";
import Story2 from "../img/Story2.avif";
import Story3 from "../img/Story3.avif";
import Story4 from "../img/Story4.avif";

const Story = () => {
  return (
    <div className="container-fluid" id="story">
      <div className="container pb-3">
        <AnimatedDiv>
          <div className="section-title position-relative text-center">
            <h6
              className="text-uppercase text-primary mb-3"
              style={{ letterSpacing: "3px" }}
            >
              Un poco de
            </h6>
            <h1 className="font-secondary display-4">Nuestra historia</h1>
            <i className="far fa-heart text-dark"></i>
          </div>
        </AnimatedDiv>
        <div className="container timeline position-relative p-0">
          <div className="row">
            <div className="col-md-6 text-center text-md-right">
              <img className="img-fluid mr-md-3" src={Story1} alt="" />
            </div>
            <div className="col-md-6 text-center text-md-left">
              <div className="h-100 d-flex flex-column justify-content-center bg-secondary p-4 ml-md-3">
                <h4 className="mb-2 text-primary-two">Nos conocimos</h4>
                <p className="m-0">en el año 2014.</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 text-center text-md-right">
              <div className="h-100 d-flex flex-column justify-content-center bg-secondary p-4 mr-md-3">
                <h4 className="mb-2 text-primary-two">Ese día</h4>
                <p className="m-0">
                  ambos llegamos al mismo tiempo a casa de una amiga.
                </p>
              </div>
            </div>
            <div className="col-md-6 text-center text-md-left">
              <img className="img-fluid ml-md-3" src={Story2} alt="" />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 text-center text-md-right">
              <img className="img-fluid mr-md-3" src={Story3} alt="" />
            </div>
            <div className="col-md-6 text-center text-md-left">
              <div className="h-100 d-flex flex-column justify-content-center bg-secondary p-4 ml-md-3">
                <h4 className="mb-2 text-primary-two">Empezamos a hablar</h4>
                <p className="m-0">
                  a través de mensajes y luego a salir en secreto hasta ponernos
                  de novios.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 text-center text-md-right">
              <img className="img-fluid mr-md-3" src={Story4} alt="" />
            </div>
            <div className="col-md-6 text-center text-md-left">
              <div className="h-100 d-flex flex-column justify-content-center bg-secondary p-4 ml-md-3">
                <h4 className="mb-2 text-primary-two">Desde entonces</h4>
                <p className="m-0">
                  nuestra historia sigue y hoy, 9 años después, damos el próximo paso.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Story;
