import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Gallery1 from "../img/Gallery1.avif";
import Gallery2 from "../img/Gallery2.avif";
import Gallery3 from "../img/Gallery3.avif";
import Gallery4 from "../img/Gallery4.avif";
import Gallery5 from "../img/Gallery5.avif";
import Gallery7 from "../img/Gallery7.avif";
import Gallery8 from "../img/Gallery8.avif";
import Gallery9 from "../img/Gallery9.avif";
import AnimatedDiv from "./AnimatedDiv";

const Gallery = () => {
  const PrevArrow = ({ onClick }) => (
    <button className="carousel-arrow prev" onClick={onClick}>
      <i className="fa fa-angle-left carousel-icon" aria-hidden="true"></i>
    </button>
  );

  const NextArrow = ({ onClick }) => (
    <button className="carousel-arrow next" onClick={onClick}>
      <i className="fa fa-angle-right carousel-icon" aria-hidden="true"></i>
    </button>
  );

  const settings = {
    autoplay: false,
    speed: 1500,
    dots: false,
    infinite: true,
    arrows: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div
      className="container-fluid bg-gallery"
      id="gallery"
      style={{ padding: "120px 0", margin: "90px 0" }}
    >
      <AnimatedDiv>
        <div
          className="section-title position-relative text-center"
          style={{ marginBottom: "120px" }}
        >
          <h6
            className="text-uppercase mb-3"
            style={{ letterSpacing: "3px", color: "white", opacity: "60%" }}
          >
            Nosotros y la gente que queremos
          </h6>
          <h1 className="font-secondary display-4 text-white">Galería</h1>
          <i className="far fa-heart text-white"></i>
        </div>
      </AnimatedDiv>
      <Slider
        {...settings}
        className="gallery-carousel"
        style={{ marginTop: "-50px" }}
      >
        <div
          className="gallery-item"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            className="img-fluid"
            src={Gallery1}
            alt=""
            style={{ objectPosition: "0px -120px" }}
          />
          <a href={Gallery1} data-lightbox="gallery">
            <i className="fas fa-search-plus text-white"></i>
          </a>
        </div>

        <div className="gallery-item">
          <img
            className="img-fluid w-100"
            src={Gallery2}
            alt=""
            style={{ objectPosition: "0px 0px" }}
          />
          <a href={Gallery2} data-lightbox="gallery">
            <i className="fas fa-search-plus text-white"></i>
          </a>
        </div>
        <div className="gallery-item">
          <img
            className="img-fluid w-100"
            src={Gallery3}
            alt=""
            style={{ objectPosition: "0px -200px" }}
          />
          <a href={Gallery3} data-lightbox="gallery">
            <i className="fas fa-search-plus text-white"></i>
          </a>
        </div>
        <div className="gallery-item">
          <img
            className="img-fluid w-100"
            src={Gallery4}
            alt=""
            style={{ objectPosition: "0px -40px" }}
          />
          <a href={Gallery4} data-lightbox="gallery">
            <i className="fas fa-search-plus text-white"></i>
          </a>
        </div>
        <div className="gallery-item">
          <img
            className="img-fluid w-100"
            src={Gallery5}
            alt=""
            style={{ objectPosition: "0px -120px" }}
          />
          <a href={Gallery5} data-lightbox="gallery">
            <i className="fas fa-search-plus text-white"></i>
          </a>
        </div>
        <div className="gallery-item">
          <img
            className="img-fluid w-100"
            src={Gallery7}
            alt=""
            style={{ objectPosition: "0px -50px" }}
          />
          <a href={Gallery7} data-lightbox="gallery">
            <i className="fas fa-search-plus text-white"></i>
          </a>
        </div>
        <div className="gallery-item">
          <img
            className="img-fluid w-100"
            src={Gallery8}
            alt=""
            style={{ objectPosition: "0px -90px" }}
          />
          <a href={Gallery8} data-lightbox="gallery">
            <i className="fas fa-search-plus text-white"></i>
          </a>
        </div>
        <div className="gallery-item">
          <img
            className="img-fluid w-100"
            src={Gallery9}
            alt=""
            style={{ objectPosition: "0px -50px" }}
          />
          <a href={Gallery9} data-lightbox="gallery">
            <i className="fas fa-search-plus text-white"></i>
          </a>
        </div>
      </Slider>
    </div>
  );
};

export default Gallery;
