import React, { useState, useEffect } from "react";
import song from "../audio/home-song.mp3";

const MusicPlayer = ({ play, setPlay }) => {
  const [audio] = useState(new Audio(song));
  const [playing, setPlaying] = useState(false);

  useEffect(() => {
    if (play) {
      const playAudio = async () => {
        try {
          await audio.play();
          setPlaying(true);
        } catch (err) {
          console.log("La reproducción automática no está permitida.");
        }
      };

      playAudio();
    } else {
      audio.pause();
      setPlaying(false);
    }
  }, [play, audio]);

  const togglePlay = () => {
    if (playing) {
      audio.pause();
      setPlay(false); 
    } else {
      audio.play();
      setPlay(true); 
    }
    setPlaying(!playing);
  };

  const divClassName = playing ? "pause" : "play";

  return (
    <div
      id="play-pause"
      onClick={togglePlay}
      className={divClassName}
      // style={{
      //   position: "fixed",
      //   bottom: 15,
      //   left: 0,
      //   padding: "10px",
      // }}
    ></div>
  );
};

export default MusicPlayer;
