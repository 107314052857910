import React, { useState, useEffect } from "react";
import Carousel1 from "../img/Carousel1.avif";
import Carousel2 from "../img/Carousel2.avif";
import Carousel3 from "../img/Carousel3.avif";
import Carousel1320 from "../img/Carousel1-320.avif";
import Carousel2320 from "../img/Carousel2-320.avif";
import Carousel3320 from "../img/Carousel3-320.avif";
import SwipeIcon from "../img/giphy.gif";
import Countdown from "react-countdown";
import CustomCountdownRenderer from "./CustomCountdownRenderer";
import MusicPlayer from "./MusicPlayer";

const addEventToGoogleCalendar = () => {
  const title = "💍🤵🏽👰🏽 Boda Miranda y Gastón";
  const description = `Ceremonia Civil: Viernes 22 de Marzo - 11:30 horas. Registro Civil de Villa Luján (Sargento Cabral 261)<br/>Ceremonia Religiosa: Sábado 11 de Mayo - 20:30 horas. Pquia San Juan Bosco (Av. Mitre 312)<br/>Fiesta: Sábado 11 de Mayo - 22:00 horas. Salón de fiestas KEKÉ (Diagonal a Tafí Viejo km 3.5. Tafí Viejo)<br/><a href="https://mirandaygaston.invitapp.com.ar">Ver invitación online</a>`;
  const location = "https://maps.app.goo.gl/5fKyVxpHde7rrF3y5";

  const startDate = new Date("2024-05-11T22:00:00");
  const endDate = new Date("2024-05-12T05:00:00");

  const formatDate = (date) => {
    const year = date.getUTCFullYear();
    const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
    const day = date.getUTCDate().toString().padStart(2, "0");
    const hours = date.getUTCHours().toString().padStart(2, "0");
    const minutes = date.getUTCMinutes().toString().padStart(2, "0");
    return `${year}${month}${day}T${hours}${minutes}00Z`;
  };

  const formattedStartDate = formatDate(startDate);
  const formattedEndDate = formatDate(endDate);

  const calendarUrl = `https://www.google.com/calendar/event?action=TEMPLATE&text=${title}&details=${description}&location=${location}&dates=${formattedStartDate}/${formattedEndDate}`;

  window.open(calendarUrl);
};

const Carousel = ({play, setPlay}) => {
  const currentDate = new Date();
  const targetDate = new Date("2024-05-11T00:00:00");
  
  const adjustedCurrentDate = new Date(currentDate.toLocaleString("en-US", { timeZone: "America/Buenos_Aires" }));
  
  const timeUntilTarget = targetDate.getTime() - adjustedCurrentDate.getTime();
  
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="container-fluid p-0" id="home">
      <div id="player-container">
        <MusicPlayer play={play} setPlay={setPlay}/>
      </div>
      <div
        id="header-carousel"
        className="carousel slide carousel-fade"
        data-ride="carousel"
      >
        <div className="carousel-inner">
          <div
            className="carousel-item position-relative active"
            style={{ height: "100vh", minHeight: "400px" }}
          >
            <div>
              <img
                alt="carosusel-image1"
                className="carousel-image"
                src={windowWidth < 768 ? Carousel1320 : Carousel1}
              />
            </div>
            <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
              <div className="p-3" style={{ maxWidth: "900px" }}>
                <h1
                  className="display-1 font-secondary text-white mt-n3 mb-md-4"
                  style={{ fontFamily: "MirandayGaston1" }}
                >
                  Miranda & Gastón
                </h1>
                <div className="d-inline-block border-top border-bottom border-light py-3 px-4">
                  <h3
                    className="text-uppercase font-weight-normal text-white m-0 getting-married"
                    style={{ letterSpacing: "5px" }}
                  >
                    ¡NOS CASAMOS!
                  </h3>
                </div>
                <div>
                  <Countdown
                    date={currentDate.getTime() + timeUntilTarget}
                    renderer={CustomCountdownRenderer}
                    zeroPadTime={2}
                  />
                  <img
                    alt="swipe-icon"
                    className="swipe-icon"
                    src={SwipeIcon}
                  />
                </div>
                <div className="save-the-date-container">
                  {" "}
                  <button
                    onClick={addEventToGoogleCalendar}
                    className="save-the-date-button"
                  >
                    Save the date 🗓️
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            className="carousel-item position-relative"
            style={{ height: "100vh", minHeight: "400px" }}
          >
            <img
              alt="carosusel-image2"
              className="carousel-image"
              src={windowWidth < 768 ? Carousel2320 : Carousel2}
            />
            <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
              <div className="p-3" style={{ maxWidth: "900px" }}>
                <h1
                  className="display-1 font-secondary text-white mt-n3 mb-md-4"
                  style={{ fontFamily: "MirandayGaston1" }}
                >
                  Miranda & Gastón
                </h1>
                <div className="d-inline-block border-top border-bottom border-light py-3 px-4">
                  <h3
                    className="text-uppercase font-weight-normal text-white m-0 getting-married"
                    style={{ letterSpacing: "5px" }}
                  >
                    ¡NOS CASAMOS!
                  </h3>
                </div>

                <div>
                  <Countdown
                    date={currentDate.getTime() + timeUntilTarget}
                    renderer={CustomCountdownRenderer}
                    zeroPadTime={2}
                  />
                  <img
                    alt="swipe-icon"
                    className="swipe-icon"
                    src={SwipeIcon}
                  />
                  <div className="save-the-date-container">
                    {" "}
                    <button
                      onClick={addEventToGoogleCalendar}
                      className="save-the-date-button"
                    >
                      Save the date 🗓️
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="carousel-item position-relative"
            style={{ height: "100vh", minHeight: "400px" }}
          >
            <img
              alt="carosusel-image2"
              className="carousel-image"
              src={windowWidth < 768 ? Carousel3320 : Carousel3}
            />
            <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
              <div className="p-3" style={{ maxWidth: "900px" }}>
                <h1
                  className="display-1 font-secondary text-white mt-n3 mb-md-4"
                  style={{ fontFamily: "MirandayGaston1" }}
                >
                  Miranda & Gastón
                </h1>
                <div className="d-inline-block border-top border-bottom border-light py-3 px-4">
                  <h3
                    className="text-uppercase font-weight-normal text-white m-0 getting-married"
                    style={{ letterSpacing: "5px" }}
                  >
                    ¡NOS CASAMOS!
                  </h3>
                </div>

                <div>
                  <Countdown
                    date={currentDate.getTime() + timeUntilTarget}
                    renderer={CustomCountdownRenderer}
                    zeroPadTime={2}
                  />
                  <img
                    alt="swipe-icon"
                    className="swipe-icon"
                    src={SwipeIcon}
                  />
                  <div className="save-the-date-container">
                    {" "}
                    <button
                      onClick={addEventToGoogleCalendar}
                      className="save-the-date-button"
                    >
                      Save the date 🗓️
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <a
          className="carousel-control-prev justify-content-start"
          href="#header-carousel"
          data-slide="prev"
        >
          <div
            className="btn btn-primary px-0"
            style={{ width: "68px", height: "68px" }}
          >
            <span className="carousel-control-prev-icon mt-3"></span>
          </div>
        </a>
        <a
          className="carousel-control-next justify-content-end"
          href="#header-carousel"
          data-slide="next"
        >
          <div
            className="btn btn-primary px-0"
            style={{ width: "68px", height: "68px" }}
          >
            <span className="carousel-control-next-icon mt-3"></span>
          </div>
        </a>
      </div>
    </div>
  );
};

export default Carousel;
