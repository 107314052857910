import React from "react";
import { Carousel } from "react-responsive-carousel";
import Testimonials1 from "../img/Testimonials1.png";
import Testimonials2 from "../img/Testimonials2.png";
import Testimonials3 from "../img/Testimonials3.png";
import Testimonials4 from "../img/Testimonials4.png";
import Testimonials5 from "../img/Testimonials5.png";
import Testimonials6 from "../img/Testimonials6.png";

import AnimatedDiv from "./AnimatedDiv";

const Testimonials = () => {
  return (
    <div className="container-fluid py-2 carousel-container" id="about">
      <div className="container py-5">
        <AnimatedDiv>
          <div className="section-title position-relative text-center">
            <h6
              className="text-uppercase text-primary mb-3 mt-3"
              style={{ letterSpacing: "3px" }}
            >
              DESEOS DE
            </h6>
            <h1 className="font-secondary display-4 mb-3">Nuestros Amigos</h1>

            <i className="far fa-heart text-dark"></i>
          </div>
        </AnimatedDiv>
        <div>
          <Carousel
            infiniteLoop={true}
            showIndicators={false}
            showThumbs={false}
            showStatus={false}
            showArrows={true}
            swipeable={false}
            renderArrowPrev={(onClickHandler, hasPrev, label) =>
              hasPrev && (
                <button
                  onClick={onClickHandler}
                  className="carousel-arrow-testimonials prev"
                >
                  <i
                    className="fas fa-angle-left"
                    style={{ color: "rgba(157, 175, 125, 255)", fontSize: "25px" }}
                  ></i>
                </button>
              )
            }
            renderArrowNext={(onClickHandler, hasNext, label) =>
              hasNext && (
                <button
                  onClick={onClickHandler}
                  className="carousel-arrow-testimonials next"
                >
                  <i
                    className="fas fa-angle-right"
                    style={{ color: "rgba(157, 175, 125, 255)", fontSize: "25px" }}
                  ></i>
                </button>
              )
            }
          >
            <div>
              <img alt="" src={Testimonials2} className="testimonials-photos" />
              <div>
                <p className="mt-3">
                  No podía esperar otro desenlace para esta historia de amor de
                  la que somos testigos hace años.
                </p>
                <h3 className="font-secondary font-weight-normal text-muted mb-3">
                  Camili Correa
                </h3>
              </div>
            </div>
            <div>
              <img alt="" src={Testimonials6} className="testimonials-photos" />
              <p className="mt-3">
                El amor para siempre necesita del respeto y la comprensión. Les
                deseo un amor para siempre y quiero sobrinos para malcriar.
              </p>
              <h3 className="font-secondary font-weight-normal text-muted mb-3">
                Gaby Garat
              </h3>
            </div>
            <div>
              <img alt="" src={Testimonials4} className="testimonials-photos" />
              <p className="mt-3">
                Un granito de mostaza que se multiplicó, por esa palmera que se
                movió y por esta historia que Dios escribió.
              </p>
              <h3 className="font-secondary font-weight-normal text-muted mb-3">
                Lucas Medina
              </h3>
            </div>
            <div>
              <img alt="" src={Testimonials3} className="testimonials-photos" />
              <p className="mt-3">
                The perfect match. Amigos queridos me siento una privilegiada de
                que compartan su camino conmigo porque veo presente en cada paso
                que dan a Dios, quien es el que los hizo encontrarse. Que sigan
                unidos en su amor y en la fe y que la Sagrada familia sea su
                modelo y guía siempre. Los adoro!
              </p>
              <h3 className="font-secondary font-weight-normal text-muted mb-3">
                Gaby Neme
              </h3>
            </div>
            <div>
              <img alt="" src={Testimonials5} className="testimonials-photos" />
              <p className="mt-3">
                Cuando pienso en esta pareja y en el camino que están
                emprendiendo me emociono mucho. El final (o nuevo comienzo)
                perfecto para dos almas perfectas. Les deseo la mejor de las
                alegrías siempre, se lo merecen.
              </p>
              <h3 className="font-secondary font-weight-normal text-muted mb-3">
                Diego Campero
              </h3>
            </div>
            <div>
              <img alt="" src={Testimonials1} className="testimonials-photos" />
              <p className="mt-3">
                A mis primos : deseo que sean muy felices , que la vida los
                encuentre siempre unidos, alegres , compañeros , pacientes ,
                poniendo apodos a todo el mundo, tomando coca cola, caminando
                juntos y tirando para el mismo lado! Los adoro !
              </p>
              <h3 className="font-secondary font-weight-normal text-muted mb-3">
                Alina Alincastro
              </h3>
            </div>
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
