import React from "react";
import InstagramLogo from "../img/Instagram-Icon.png";

const Footer = () => {
  return (
    <div className="container-fluid" id="about">
      <div className="container py-5">
        <div className="col-md-12 text-center">
          <p>
            <small className="block">
              Created by{" "}
              <a
                href="https://instagram.com/invitapp.tuc"
                target="_blank"
                rel="noreferrer"
                style={{ color: "#9b9f7a" }}
              >
                Invitapp
                <img
                  src={InstagramLogo}
                  className="contact-image"
                  style={{ marginTop: "-5px" }}
                />
              </a>
              <div>&copy; 2024. All Rights Reserved.</div>
            </small>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
